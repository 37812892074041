import {
  DurationStatisticsResponseModel,
  TeamDurationStats,
  UserDurationStats,
} from '@capturi/api-insights'
import { useTeams } from '@capturi/core'
import { Segment } from '@capturi/filters'
import { useUsers } from '@capturi/stores'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { TeamSegments, UserSegments } from '../../shared/types'

type Datum = {
  conversations: number
  conversationsPercent: number
  conversationsTotal: number
  avg: number
}

export type UserSegmentsData = UserSegments<Datum>

function getSegmentData(entries: UserDurationStats[] | TeamDurationStats[]): {
  [key: string]: Datum
} {
  const x = entries.reduce<{
    [key: string]: Datum
  }>((acc, x) => {
    acc[x.uid] = {
      conversations: x.conversations,
      conversationsPercent: x.conversationsPercent / 100,
      conversationsTotal: x.conversationsTotal,
      avg: x.avg,
    }
    return acc
  }, {})
  return x
}

function getSegments(
  segments: Segment<DurationStatisticsResponseModel>[],
  segmentData: { [key: string]: Datum }[],
  uid: string,
): Segment<Datum>[] {
  return segments.map<Segment<Datum>>((s, i) => {
    const map = segmentData[i]
    return {
      color: s.color,
      label: s.label,
      data: map[uid] ?? {
        conversations: 0,
        conversationsPercent: 0,
        conversationsTotal: 0,
        avg: 0,
      },
    }
  })
}

export function useConversationDataStats(
  segments: Segment<DurationStatisticsResponseModel>[],
  orderByProperty: keyof Datum = 'conversationsPercent',
): UserSegmentsData[] {
  const { getUserByUid } = useUsers()

  return useMemo(() => {
    const allUserUids = new Set(
      segments.flatMap((s) => (s.data?.users ?? []).map((x) => x.uid)),
    )
    const segmentUserData = segments.map((s) =>
      getSegmentData(s.data?.users ?? []),
    )
    const userSegmentData = [...allUserUids].reduce<{
      [key: string]: UserSegmentsData
    }>((acc, userUid) => {
      acc[userUid] = {
        segments: getSegments(segments, segmentUserData, userUid),
        user: getUserByUid(userUid),
      }
      return acc
    }, {})

    return orderBy(
      Object.values(userSegmentData),
      [(x) => x.segments[0]?.data?.[orderByProperty], (x) => x.user.name],
      ['desc', 'asc'],
    )
  }, [segments, getUserByUid, orderByProperty])
}

export type TeamSegmentsData = TeamSegments<Datum>

export function useConversationDataTeamStats(
  segments: Segment<DurationStatisticsResponseModel>[],
  orderByProperty: keyof Datum = 'conversationsPercent',
): TeamSegmentsData[] {
  const { getTeamByUid } = useTeams()
  return useMemo(() => {
    const allTeamUids = new Set(
      segments.flatMap((s) => (s.data?.teams ?? []).map((x) => x.uid)),
    )
    const segmentTeamData = segments.map((s) =>
      getSegmentData(s.data?.teams ?? []),
    )
    const teamSegmentData = [...allTeamUids].reduce<{
      [key: string]: TeamSegmentsData
    }>((acc, teamUid) => {
      acc[teamUid] = {
        segments: getSegments(segments, segmentTeamData, teamUid),
        team: getTeamByUid(teamUid) ?? {
          uid: teamUid,
          name: 'N/A',
          members: [],
        },
      }
      return acc
    }, {})

    return orderBy(
      Object.values(teamSegmentData),
      [(x) => x.segments[0]?.data?.[orderByProperty], (x) => x.team.name],
      ['desc', 'asc'],
    )
  }, [segments, getTeamByUid, orderByProperty])
}
