import {
  DurationStatisticsTimeSeriesBucket,
  insightsAPI,
} from '@capturi/api-insights'
import {
  CartesianGrid,
  DateBucket,
  LineChartSkeleton,
  LineDescriptor,
  PrimaryLine,
  TimeSeriesChart,
  TimeSeriesTooltip,
  XAxis,
  YAxis,
  adjustDateForDSTOverlap,
} from '@capturi/charts'
import { TimeResolution } from '@capturi/core'
import {
  Segment,
  useFetchSegments,
  useFilterPeriodContext,
} from '@capturi/filters'
import { Box, HStack } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { isWeekend } from 'date-fns'
import React from 'react'
import { ResponsiveContainer, Tooltip } from 'recharts'

import ResolutionButtons from '../../../shared/components/ResolutionButtons'
import { useTimeResolutionContext } from '../../../shared/contexts/ResolutionContext'
import useOnlyWeekdaysToggle from '../../../shared/hooks/useOnlyWeekdaysToggle'
import { pctFormat } from '../../../shared/utils'
import { Event, logEvent } from '../../events'

export const TimeSeries: React.FC = () => {
  const { resolution, setResolution } = useTimeResolutionContext()
  const { period } = useFilterPeriodContext()
  const tzOffset = period.from.getTimezoneOffset() * -1
  const [plotOnlyWeekdays, OnlyWeekdaysToggle] = useOnlyWeekdaysToggle()

  const onResolutionChange = (resolution: TimeResolution): void => {
    setResolution(resolution)
    logEvent(Event.TimeSeries_ChangeResolution, 'conversations-count', {
      resolution,
    })
  }

  const { segments, isLoading } = useFetchSegments<{
    series: DurationStatisticsTimeSeriesBucket[]
  }>(() => insightsAPI.getDurationStatisticsTimeSeries(resolution, tzOffset))

  const results = React.useMemo(() => {
    const buckets: Record<string, DateBucket> = {}

    const getOrCreateBucket = (date: Date): DateBucket => {
      const bucketKey = date.toISOString()
      if (buckets[bucketKey] === undefined) {
        buckets[bucketKey] = {
          date,
        }
      }
      return buckets[bucketKey]
    }

    function addSegmentDataToBucket(
      segments: Segment<{
        series: DurationStatisticsTimeSeriesBucket[]
      } | null>[],
    ): void {
      segments.forEach((s) => {
        ;(s.data?.series ?? []).forEach((x) => {
          const date = adjustDateForDSTOverlap(x.dateTime, tzOffset)

          if (
            plotOnlyWeekdays &&
            isWeekend(date) &&
            (resolution === 'Hours' || resolution === 'Days')
          ) {
            return
          }

          const segmentKey = s.label
          const segmentColor = s.color
          const { dateTime, conversations, conversationsPercent, ...restData } =
            x
          const bucket = getOrCreateBucket(date)
          const value = conversations
          const datum = {
            value,
            segmentColor,
            hitRate: conversationsPercent / 100,
            ...restData,
          }

          bucket[segmentKey] = datum
        })
      })
    }

    // Add primary segment data
    addSegmentDataToBucket(segments)

    const segmentLines = segments.reduce<LineDescriptor[]>((memo, s) => {
      // Only add lines when we also have data. Charts will do weird aninmations otherwise.
      if (s.data == null) return memo
      memo.push({
        id: `primary:${s.label}:${s.color}`,
        color: s.color,
        label: s.label,
        getValue: (d) => {
          return d[s.label]?.value
        },
      })
      return memo
    }, [])

    return {
      dataset: Object.values(buckets),
      segmentLines,
    }
  }, [segments, plotOnlyWeekdays, tzOffset, resolution])

  return (
    <Box>
      <HStack spacing={4} mb={2}>
        <ResolutionButtons
          resolution={resolution}
          setResolution={onResolutionChange}
        />
        {OnlyWeekdaysToggle}
      </HStack>
      <Box w="100%" h="14rem" mt={4}>
        <LineChartSkeleton isLoaded={!isLoading}>
          <ResponsiveContainer width="100%" height="100%" minWidth={100}>
            <TimeSeriesChart data={results.dataset} resolution={resolution}>
              <CartesianGrid />
              <Tooltip
                content={
                  <TimeSeriesTooltip
                    resolution={resolution}
                    formatValue={(value, name, d) => {
                      const hitRate = d[name].hitRate
                      const total = d[name].conversationsTotal
                      const numberValue = i18n.number(hitRate, pctFormat)
                      return t`${value} (${numberValue} of ${total} conversations)`
                    }}
                  />
                }
              />
              <XAxis dataKey="date" />
              <YAxis />
              {results.segmentLines.map((line) => (
                <PrimaryLine key={line.id} line={line} />
              ))}
            </TimeSeriesChart>
          </ResponsiveContainer>
        </LineChartSkeleton>
      </Box>
    </Box>
  )
}
