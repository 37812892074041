import { ActivitySummaryResponseModel } from '@capturi/api-insights'
import { Segment } from '@capturi/filters'
import { SectionHeading } from '@capturi/ui-components'
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import DataBasisOverview from '../../../shared/components/DataBasisOverview'
import {
  TeamSegmentsData,
  UserSegmentsData,
} from '../../data/useConversationDataStats'
import { TeamBreakdown } from './TeamBreakdown'
import { TimeSeries } from './TimeSeries'
import { UserBreakdown } from './UserBreakdown'

export type ConversationsCountProps = {
  summaries: Segment<ActivitySummaryResponseModel>[]
  onPlaySnippets: (
    row: UserSegmentsData | TeamSegmentsData,
    segmentIndex: number,
  ) => void
}

export const ConversationsCount: React.FC<ConversationsCountProps> = ({
  summaries,
  onPlaySnippets,
}) => {
  return (
    <Box>
      <SectionHeading>
        <Trans>Conversation count</Trans>
      </SectionHeading>
      <DataBasisOverview segments={summaries} mb={4} />
      <TimeSeries />

      <Tabs isLazy mt={4}>
        <TabList>
          <Tab key="employees">{t`Employees`}</Tab>
          <Tab key="teams">{t`Teams`}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel key="employees" px={0}>
            <UserBreakdown
              summaries={summaries}
              onPlaySnippets={onPlaySnippets}
            />
          </TabPanel>
          <TabPanel key="teams" px={0}>
            <TeamBreakdown
              summaries={summaries}
              onPlaySnippets={onPlaySnippets}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
